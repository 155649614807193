//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '~/components/UI/Logo.vue'
import HeaderPopoverAction from '~/components/layouts/Header/components/HeaderPopoverAction.vue'
import setDeviceType from '~/mixins/setDeviceType'
export default {
  components: {
    Logo,
    HeaderPopoverAction,
    PopoverContacts: () =>
      import('~/components/modules/PopoverBlocks/PopoverContacts.vue'),
  },
  mixins: [setDeviceType],
  methods: {
    onClose() {
      const popover = this.$refs['header-popover']
      popover.$refs.popover.onClose()
    },
  },
}
