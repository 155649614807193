//
//
//
//
//
//
//
//
//
//
//

import Header from '~/components/layouts/Header/Header.vue'
import setDeviceType from '~/mixins/setDeviceType'
export default {
  components: {
    Header,
    Footer: () => import('~/components/layouts/Footer/Footer.vue'),
    NewsletterModal: () => import('~/components/UI/Modal/Newsletter.vue'),
  },
  mixins: [setDeviceType],
  async mounted() {
    await this.$store.dispatch('cart/FETCH_CART_PRODUCTS')
  },
}
